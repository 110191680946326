import { Questionaires } from 'assets';

export const getActivityCoef = (QnA_ID: string, totalPoints): number => {
  const questionaire = Questionaires.find(
    (que) => que.questionaireID === QnA_ID
  );

  return questionaire?.PointsToActivityRatio[totalPoints] ?? 0;
};

export const hasAnyZeroes = (arr: number[]): boolean => {
  let answer = false;
  arr.forEach((num) => {
    if (num === 0) answer = true;
  });
  return answer;
};
