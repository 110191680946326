import { Redirect, Route, BrowserRouter as Router } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import CalculatorPage from './pages/CalculatorPage/Calculator';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { GlobalHeader } from './components/Molecules/AppBar/AppBar';

const App: React.FC = () => (
  <IonApp>
    <Router>
      <Route exact path="/home">
        <Home />
      </Route>
      <Route exact path="/calculator">
        <CalculatorPage />
      </Route>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
    </Router>
  </IonApp>
);

export default App;
