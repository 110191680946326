import { IonPage } from '@ionic/react';
import { Button } from '@material-ui/core';

import './Home.css';

import { GlobalHeader } from '../components/Molecules/AppBar/AppBar';

const Home: React.FC = () => {
  return (
    <div>
      <GlobalHeader />
      <h3>Еми няма много неща. Горе-вдясно освен един калкулатор</h3>
    </div>
  );
};

export default Home;
