import {
  ActivityCalculatorQuestionsAndAnswers,
  PointsToActivityRatio,
} from './questionaires';
import { T_Activity_calculator, T_activity_question, T_answer } from 'types';

export const QuestionaireIDs = ['LeighPeele_Fat_Loss_Troubleshooter_go_buy_it'];

export const Questionaires = [
  {
    QuestionsAndAnswers: [...ActivityCalculatorQuestionsAndAnswers],
    questionaireID: QuestionaireIDs[0],
    PointsToActivityRatio: { ...PointsToActivityRatio },
  },
];

const getEmptyAnswersArray = (QnA_ID) => {
  const nullAnswers = [];
  const questionaire = Questionaires.find(
    (que) => que.questionaireID === QnA_ID
  );
  questionaire.QuestionsAndAnswers.forEach((que) => {
    nullAnswers.push(null);
  });
  return nullAnswers;
};

const DEFAULT_ACTIVITY_CALCULATION: T_Activity_calculator = {
  answers: getEmptyAnswersArray(QuestionaireIDs[0]),
  activityCoef: null,
  questionsID: QuestionaireIDs[0],
};
