import { GlobalHeader } from '../../components/Molecules/AppBar/AppBar';
import Questionaire from './components/Questionaire';

const CalculatorPage = () => {
  return (
    <div>
      <GlobalHeader />
      <Questionaire />
    </div>
  );
};
export default CalculatorPage;
