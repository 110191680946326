import { Questionaires } from 'assets';
import { T_activity_question, T_answer } from 'types';

import { Button, Menu, MenuItem, Box } from '@material-ui/core';
import { useRef, useState } from 'react';
import { getActivityCoef, hasAnyZeroes } from 'utils';

type defaultMeasurementQuestion = {
  isOpen: boolean;
  answer: string | null;
  points: number;
};

const Questionaire = () => {
  const Questionaire = Questionaires[0];

  const defaultMeasurementQuestions: defaultMeasurementQuestion[] =
    Questionaire.QuestionsAndAnswers.map((item) => {
      return {
        isOpen: false,
        answer: null,
        points: 0,
      } as defaultMeasurementQuestion;
    });

  const [state, setState] = useState({
    measurements: defaultMeasurementQuestions,
    totalPoints: 0,
    result: 0,
  });

  const onToggle = (index: number, newAnswer: T_answer) => {
    const newMeasurements = state.measurements.map((item, itemIndex) => {
      if (itemIndex === index)
        return {
          ...item,
          isOpen: !item.isOpen,
          answer: newAnswer.answerString,
          points: newAnswer.points,
        };
      return item;
    });

    const newPoints = newMeasurements.reduce(
      (accumulator, item) => accumulator + item.points ?? 0,
      0
    );
    const newResult = getActivityCoef(Questionaire.questionaireID, newPoints);
    setState({
      ...state,
      measurements: newMeasurements,
      totalPoints: newPoints,
      result: newResult,
    });
  };

  const areAllAnswered = !hasAnyZeroes(
    state.measurements.map((item) => item.points)
  );

  return (
    <div
      style={{
        display: 'flex',
        height: window.innerHeight - window.innerHeight / 7,
        flexDirection: 'column',
        marginLeft: 10,
      }}
    >
      <div style={{ flex: 1 }}>
        <h3>Въпросник за активност* </h3>
        {Questionaire.QuestionsAndAnswers.map((item, qIndex) => {
          return (
            <RenderQuestion
              item={item}
              key={qIndex}
              state={state.measurements[qIndex]}
              onToggle={(newAnswer: T_answer) => onToggle(qIndex, newAnswer)}
            />
          );
        })}
        <p>Точки: {state.totalPoints ?? ''}</p>
        Коефициент на активност:{' '}
        {areAllAnswered ? state.result : 'Отговори на всички въпроси първо'}
      </div>
      <div
        style={{
          //   height: window.innerHeight,
          display: 'flex',
          flex: 10,
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <h6>*Приспособен от https://www.fatlosstroubleshoot.com/ </h6>
      </div>
    </div>
  );
};

export default Questionaire;
type RenderQuestionProps = {
  state: defaultMeasurementQuestion;
  item: T_activity_question;
  onToggle: (newAnswer: T_answer) => void;
};
const RenderQuestion: React.FC<RenderQuestionProps> = ({
  item,
  state,
  onToggle,
}) => {
  const Q_Item: T_activity_question = item;
  const anchorEl = useRef<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    anchorEl.current = event.currentTarget;
    onToggle({ answerString: null, points: 0 });
  };

  const handleClose = () => {
    anchorEl.current = null;
  };
  return (
    <div style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}>
      <Button onClick={handleClick} style={{ fontSize: 15 }}>
        {Q_Item.question}
      </Button>{' '}
      {state.answer ? (
        <div style={{ color: 'green' }}>{state.answer}</div>
      ) : (
        <div style={{ color: 'grey' }}>{`отговор`}</div>
      )}
      <div style={{ height: 15 }}></div>
      {/* <h3></h3> */}
      <Menu
        open={state.isOpen}
        anchorEl={anchorEl.current}
        onClose={handleClose}
      >
        {Q_Item.question}
        {Q_Item.answers.map((answer, index) => {
          return (
            <MenuItem
              onClick={() => onToggle(Q_Item.answers[index])}
              key={index}
            >
              {answer.answerString}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
